<template>
  <v-row class="d-flex mt-3 px-3">
    <img
      :src="profileImage"
      alt=""
      class="digital-persona__avatar mr-4"
    >
    <div>
      <div
        v-if="fullName"
        class="digital-persona__name"
      >
        {{ fullName }}
      </div>
      <div
        v-else
        class="digital-persona__name"
      >
        {{ email }}
      </div>
      <div class="gray-text">
        {{ contact_details.specialty }}
      </div>
    </div>
    <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
    <div
      v-if="$vuetify.breakpoint.smAndUp"
      class="action-panel-buttons d-flex align-baseline"
    >
      <slot />
    </div>
  </v-row>
</template>

<script>
export default {
  name: "Header",
  props: {
    contact_details: {
      default: () => {},
    },
  },
  computed: {
    fullName() {
      if (!this.contact_details.firstName && !this.contact_details.lastName) {
        return '';
      }

      return `${this.contact_details.salutation ?? ''}
       ${this.contact_details.firstName}
       ${this.contact_details.lastName}
       ${this.contact_details.credentials ?? ''}`;
    },
    email() {
      if (!this.contact_details?.contactEmails?.length) {
        return '(Name not available)';
      }

      return this.contact_details.contactEmails[0].emailAddress + ' (Name not available)';
    },
    profileImage() {
      if (this.contact_details.pictureUrl) {
        return this.contact_details.pictureUrl;
      }
      if (this.contact_details.contactEmails?.length) {
        return this.contact_details.contactEmails[0].gravatarUrl + "?d=mp";
      }

      return 'https://www.sideralsoft.com/wp-content/uploads/2019/03/user-placeholder.png';
    },
  },
};
</script>

<style scoped>
.contact-profile-block .digital-persona__name {
  max-width: 600px !important;
  white-space: normal;
}
.digital-persona__name {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>